<script lang='js'>
import {
  Doughnut
} from 'vue-chartjs'
export default {
  extends: Doughnut,
  props: {
    labels: {
      type: Array,
      default: () => ["Open", "Won","Lost"]
    },
    percentages: {
      type: Array,
      default: () => [100, 0,0]
    }
  },
  watch: {
    percentages: function(newVal) { // watch it
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
          labels: this.labels,
          data: newVal,
          backgroundColor: [
                "#111111","#00d25b","#D5141C"
              ],
          borderWidth: 0
          }
        ]
        }, {
          responsive: true,
          cutoutPercentage: 70,
          animation: {
            animateScale: true,
            animateRotate: true
          },
          legend: false
      })
    }
  },
  data () {
    return {
      gradient: null,
      gradient2: null
    }
  },
  mounted () {
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
        labels: this.labels,
        data: this.percentages,
        backgroundColor: [
              "#efef12","#47a447","#ff706b"
            ],
        borderWidth: 0
        }
      ]
      }, {
        responsive: true,
        cutoutPercentage: 70,
        animation: {
          animateScale: true,
          animateRotate: true
        },
        legend: false
    })
  }
}
</script>

<style scoped lang='scss'>
  .transactionHistoryChart {

  }
</style>
